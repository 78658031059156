import styled from 'styled-components';
import logo2 from '../../assests/images/mihint-logo2.png';
// import Icon from 'utlils/Icons/Icon';
import { useState } from 'react';
// import { OptionWrapper } from 'components';
import { useNavigate } from 'react-router-dom';

const HomeFooterWrapper = styled.div`
  background: #292d32;
  color: #ffffff;
  padding: 20px;

  & .hfw__ {
    &wrp {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 28px;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      flex-wrap: wrap;
      &__logo-section {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__drop-down-title {
        cursor: pointer;
      }
      &__dropdown-icon {
        position: relative;
        top: 2px;
        left: 6px;
      }
    }
  }
`;

export const HomeFooter = ({ options }) => {
  const [active, setActive] = useState(options.components[0].id);
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();

  const onClickDropDown = (option) => {
    setActive(option.id);
    setOpenDropdown(!openDropdown);
    if (option.url !== '') {
      navigate(option.url);
    }
  };
  return (
    <HomeFooterWrapper className="hfw">
      <div className="container">
        <div className="row">
          <div className="hfw__wrp">
            <div className="hfw__wrp__logo-section">
              <img src={logo2} alt="logo" />
              <span>&#169;</span>
              <span>{options?.copyrightYear}</span>
            </div>
            {options?.components?.length > 0 &&
              options?.components?.map((option, i) => {
                const isDropdownItem = option?.isDropdown;

                return (
                  <div key={option.id + '--' + i}>
                    <div
                      key={option.id}
                      onClick={() => onClickDropDown(option)}
                      className="hfw__wrp__drop-down-title">
                      <span>{option?.title}</span>
                      {/* {isDropdownItem && (
                        <Icon
                          name={
                            active === option.id && openDropdown
                              ? 'arrow-drop-up'
                              : 'arrow-down'
                          }
                          color="#ffffff"
                          className="hfw__wrp__dropdown-icon"
                          size={active === option.id && openDropdown ? 18 : 22}
                        />
                      )} */}
                    </div>
                    {/* {active === option.id && openDropdown && isDropdownItem && (
                      <OptionWrapper
                        className="option-wrapper"
                        // onClick={onClickDropdownMenu}
                        options={option?.dropDownItems}
                        //selectedOption={selectedDropdown}
                      />
                    )} */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </HomeFooterWrapper>
  );
};
