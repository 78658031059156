import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from "./navbar";
import { BasicDetails } from "./basic_details";
import { ProfessionalDetails } from "./professional_details";
import { DEFAULT_USER_DATA } from "../../utlis/constants";
import { EducationalDetails } from "./educational_details";

export const UserProfile = () => {
  const [userState, setUserState] = useState(DEFAULT_USER_DATA);

  const setter = (val = "", label = "") => {
    setUserState((curr) => ({ ...curr, [label]: val }));
    let modifiedData = userState;
    modifiedData[label] = val;
    localStorage.setItem("userData", JSON.stringify(modifiedData));
  };

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      console.log("found");
      setUserState(JSON.parse(localStorage.getItem("userData")));
    } else {
      console.log("not found");

      setUserState(JSON.stringify(DEFAULT_USER_DATA));
    }
  }, []);
  return (
    <UserProfileWrapper>
      <Navbar />
      <div className="row row main-container">
        <Routes>
          <Route
            path="basic_details"
            element={<BasicDetails getter={userState} setter={setter} />}
          />
          <Route
            path="professional_details"
            element={<ProfessionalDetails getter={userState} setter={setter} />}
          />
          <Route
            path="educational_details"
            element={<EducationalDetails getter={userState} setter={setter} />}
          />
        </Routes>
      </div>
    </UserProfileWrapper>
  );
};

const UserProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #414f66;
  align-items: center;
  background-color: #f5f6fc;
  width: 100%;
  height: 100%;

  .navbar {
    width: 100%;
    filter: drop-shadow(0 0 0.12rem #afaeae);
    background-color: #fffefe;
    padding: 0 12px;
    img {
      scale: 0.8;
    }
    .logout-btn {
      background-color: #f5f5f5;
      border: 2px solid #e55151;
      border-radius: 5px;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 500;
      color: #e55151;
    }
  }
  .main-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 64px;
    padding: 12px 40px;
    margin-top: 24px;
    .sideBar {
      width: 15%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #000;
      filter: drop-shadow(0 0 0.12rem #afaeae);
      padding: 16px 12px;
      .sideBar-info {
        border-radius: 6px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 40px;
        background-color: #fffefe;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        gap: 18px;
        .profilePic {
          position: relative;
          top: -60px;
          aspect-ratio: 1/1;
          width: 100px;
          background-color: #d7f3f8;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin-top: 14px;
            font-size: 42px;
            font-weight: 600;
            color: #2b3442;
          }
        }
        .side-user-info {
          margin-top: -60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            font-size: 14px;
            font-weight: 400;
            color: #404f67;
          }
        }
      }

      .sideBar-nav {
        height: auto;
        display: flex;
        width: 120%;
        background-color: #fffefe;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6px;
        gap: 12px;
        font-size: 16px;
        font-weight: 400;
        margin-top: 120px;
        text-align: left;
        .sideBar-items {
          cursor: pointer;
          width: 100%;
          padding: 6px 12px;
          color: #0f151dc7;
          border-radius: 6px;
        }

        .active {
          color: #080411;
          font-weight: 600;
          border: 2px solid #0abd00;
        }
      }
    }
    .profile-view-container {
      border-radius: 5px;
      width: 55%;
      min-height: 75dvh;
      padding: 16px 12px;
      color: #404f67;
      filter: drop-shadow(0 0 0.12rem #afaeae);
      background-color: #fffefe;

      .profile-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 9dvh;
        padding: 8px 4px;
        .edit-btn {
          font-size: 16px;
          font-weight: 500;
          color: #0abd00;
          border: 2px solid #0abd00;
          border-radius: 5px;
          padding: 8px 12px;
        }
        .cancel-btn {
          font-size: 16px;
          font-weight: 500;
          color: #bd0000;
          border: 2px solid #bd0000;
          border-radius: 5px;
          padding: 8px 12px;
        }
        hr {
          max-inline-size: 2px;
        }
      }
      .profile-view-text {
        padding: 4px 8px;
      }

      .profile-view-text::before {
        content: "";
        background-color: #0abd00;
        position: absolute;
        width: 10px;
        height: 36px;
        border-radius: 5px;
        left: 6px;
        top: 24px;
      }

      .label {
        font-size: 14px;
        font-weight: 600;
      }
      .box-container {
        background-color: #fffefe;
      }
      .value {
        word-break: break-word;
        font-size: 12px;
        font-weight: 400;
      }
      hr {
        border: 1px solid #7c8ba4;
      }
      .about-txt {
        align-self: center;
        justify-self: center;
        word-break: break-word;
        font-size: 14px;
        font-weight: 500;
        color: #7c8ba4;
        line-height: 1.5;
      }
    }
  }
  .modal-popup {
    position: absolute;
    top: 30%;
    left: 0;
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 36px;
      .modal-text {
        color: #6b727e;
      }
      .btn-group {
        display: flex;
        gap: 12px;
        .modal-btn {
          border: none;
          filter: drop-shadow(0 0 0.12rem #afaeae);
          font-weight: 500;
          padding: 8px 16px;
        }
        .primary {
          background-color: #0abd00;
          color: #fffefe;
        }
        .secondary {
          background-color: #e2e1e1;
          color: #7c8ba4;
        }
      }
    }
  }
`;
