import React from "react";
import styled from "styled-components";

export const Input = (props) => {
  const {
    type = "text",
    placeholder = "Enter Input data here",
    disabled = false,
    value = "",
    onChange = () => {},
    onBlur = () => {},
    className = "",
    ...rest
  } = props;
  return (
    <InputWrapper>
      <input
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...rest}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  input {
    min-width: 80px;
    min-height: 50px;
    border-radius: 10px;
    border: 1px solid #9f9f9f;
    filter: drop-shadow(0 0 0.1rem #2b2b2b);
  }
`;
