import styled from 'styled-components';
import GridBg from '../../assests/images/homepage/grid-bg.png';
import { useNavigate } from 'react-router-dom';

const PosterWrapper = styled.div`
  padding: 220px 0;
  background: url(${GridBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 991px) {
    padding: 200px 0;
    margin: 0 8px;
  }
  & .pw__ {
    &wrp {
      background: #292d32;
      box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.45);
      border-radius: 30px;
      padding: 28px;
      color: #ffffff;
      text-align: center;
      display: grid;
      gap: 16px;

      &__heading {
        font-weight: 600;
        font-size: 30px;
        margin: 0;
        @media (max-width: 991px) {
          font-size: 24px;
        }
      }
      &__description {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        margin-top: 16px;
        justify-content: center;
        gap: 16px;

        @media (max-width: 506px) {
          display: block;
        }
      }
    }
  }
`;

export const Poster = ({ content }) => {
  const navigate = useNavigate();

  return (
    <PosterWrapper className="pw">
      <div className="container">
        <div className="row">
          <div className="pw__wrp">
            <p className="pw__wrp__heading">{content?.heading}</p>
            <div className="pw__wrp__description">{content?.description}</div>
            <div className="pw__wrp__action">
              {/* <Button
                label={'Get Started'}
                backgroundColor="#219FFF"
                buttonStyle={{
                  borderRadius: '10px',
                  padding: '20px',
                  marginBottom: '12px',
                }}
                onClick={() => navigate(content.action)}
              />
              <Button
                outlined
                label={'View Pricing'}
                color="#219FFF"
                buttonStyle={{
                  borderRadius: '10px',
                  padding: '20px',
                  marginBottom: '12px',
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </PosterWrapper>
  );
};
