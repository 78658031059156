import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import { Signin } from './Pages/Signin';
import { UserProfile } from './Pages/UserProfile/UserProfile';
import { NotFound } from './Pages/NotFound';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/signin' element={<Signin />}/>
        <Route path='/userProfile/*' element={<UserProfile />}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
      
    </div>
  );
}

export default App;
