import React from 'react'
import newLogo from "../../../assests/images/new-logo.png";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
    const navigate = useNavigate();

    
  const Logouthandler = () => {
    localStorage.removeItem("email");
    navigate("/");
  };

  return (
    <nav className="navbar">
        <img src={newLogo} alt="logo" />
        <button className="logout-btn" onClick={Logouthandler}>
          Logout
        </button>
      </nav>
  )
}
