export const usersData = [
  {
    // firstName: "John",
    // lastName: "Doe",
    // country: "Australia",
    // state: "New South Wales",
    // city: "Sydney",
    email: "john@email.com",
    password: '12345678',
    // contact: "1234567890",
    // languages: "English",
    // about:
    //   " I am a 34 years old developer and mainly deal with system designing and security. I like cycling and swimming on my weekends and go for a long hike.",
  },
];
