import React from 'react'
import styled from 'styled-components'
import FeatureImg from '../../assests/images/homepage/modern-feature.png';
// import FeatureImgMobile from '../../assests/images/homepage/modern-feature-mobile.png';

export default function ModernFeature({contant}) {
  return (
    <ModernFeatureWrapper className='mfcw'>
        <div className="container">
        <div className="row">
          <div className="mfcw__wrp">
            {/* <Icon name={content.header_icon} color="#219fff" size={35} /> */}
            <div className="mfcw__wrp__sub-heading">
              Features that make people love us
            </div>
            <div className="mfcw__wrp__heading">
              Most Unique & Modern Features
            </div>
          </div>
        </div>
      </div>
    </ModernFeatureWrapper>
  )
}

const ModernFeatureWrapper = styled.div`
  background-image: url(${FeatureImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 62vh;
  width: 100%;
  margin: 5em 0 0;
  .mfcw__ {
    &wrp {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-top: 230px;
      gap: 8px;
      &__sub-heading {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        color: #7e8f9a;
        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
      &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        color: #219fff;
        text-align: center;
        @media (max-width: 991px) {
          font-size: 26px;
        }
      }
    }
  }
`;