export const DEFAULT_USER_DATA = {
    basic_details: {
      firstName: "",
      lastName: "",
      contact: "",
      country: "",
      state: "",
      city: "",
      languages: "",
      link: "",
      about: "",
    },
    educational_details: {
      instituteName: "",
      degreeType: "",
      course: "",
      location: "",
      from: "",
      to: "",
    },
    professional_details: {
      companyName: "",
      location: "",
      role: "",
      from: "",
      to: "",
      description: "",
    },
  };