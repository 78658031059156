import { useEffect, useState } from "react";
import newLogo from "../assests/images/new-logo.png";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { usersData } from "../utlis/users";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEFAULT_USER_DATA } from "../utlis/constants";

const defaultUserState = { email: "", password: "" };

export const Signin = () => {
  const [userState, setUserState] = useState(defaultUserState);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const onChangeHandler = (val = "", label = "") => {
    setUserState((prev) => ({ ...prev, [label]: val }));
  };

  useEffect(() => {
    let storedEmail = localStorage.getItem("email");
    let isVerified = usersData.some((item) => {
      return item.email === localStorage.getItem("email");
    });
    if (storedEmail && isVerified) {
      navigate("/userProfile/basic_details");
    }
  }, []);

  const signIn = () => {
    if (
      usersData.some((item) => {
        return item.email === userState.email;
      })
    ) {
      if (
        usersData.some((item) => {
          return item.password === userState.password;
        })
      ) {
        localStorage.setItem("email", userState.email);
        if (!localStorage.getItem("userData")) {
          localStorage.setItem("userData", JSON.stringify(DEFAULT_USER_DATA));
        }
        navigate("/userProfile/basic_details");
      } else {
        alert("Wrong password");
      }
    } else {
      alert("No such user Found");
    }
  };

  return (
    <SigninPageWrapper>
      <div className="app-wrapper">
        <div className="container-fluid" style={{ height: "auto" }}>
          <div className="row sign-in-container">
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <div style={{ height: "auto" }}>
                      <div
                        className="sign-in-wrapper"
                        style={{ height: "auto" }}
                      >
                        <div
                          style={{
                            marginTop: "33px",
                            marginBottom: "24px",
                            height: "auto",
                          }}
                        >
                          <img
                            src={newLogo}
                            className="img common-navbar-logo"
                            alt="logo"
                          />
                        </div>
                        <div style={{ marginBottom: "20px", height: "auto" }}>
                          <div className="title-box">
                            <div className="title">
                              <span>Welcome</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 col-lg-10 col-12">
                            <div style={{ marginTop: "20px", height: "auto" }}>
                              <div
                                className="custom-input"
                                style={{ marginBottom: "20px" }}
                              >
                                <label className="form-label label-style">
                                  Email Address
                                  <span style={{ color: "rgb(255, 30, 38)" }}>
                                    *
                                  </span>
                                </label>
                                <div className="input-container lg">
                                  <svg
                                    className="sc-bdvvtL dTMWEz"
                                    color="#CCD2DD"
                                    fill="none"
                                    height="25px"
                                    viewBox="0 0 24 24"
                                    width="25px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <path
                                        d="M13.4583 16.7291H5.54159C3.16659 16.7291 1.58325 15.5416 1.58325 12.7708V7.22909C1.58325 4.45825 3.16659 3.27075 5.54159 3.27075H13.4583C15.8333 3.27075 17.4166 4.45825 17.4166 7.22909V12.7708C17.4166 15.5416 15.8333 16.7291 13.4583 16.7291Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M13.4584 7.625L10.9805 9.60417C10.1651 10.2533 8.82716 10.2533 8.01174 9.60417L5.54175 7.625"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </g>
                                  </svg>
                                  <input
                                    className="input-box"
                                    type="email"
                                    placeholder="Enter your email address"
                                    value={userState.email}
                                    onChange={(e) =>
                                      onChangeHandler(e.target.value, "email")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{ height: "auto" }}>
                              <div
                                className="custom-input"
                                style={{ marginBottom: "12px" }}
                              >
                                <label className="form-label label-style">
                                  Password
                                  <span style={{ color: "rgb(255, 30, 38)" }}>
                                    *
                                  </span>
                                </label>
                                <div className="input-container lg">
                                  <svg
                                    className="sc-bdvvtL dTMWEz"
                                    color="#CCD2DD"
                                    fill="none"
                                    height="25px"
                                    viewBox="0 0 24 24"
                                    width="25px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <path
                                        d="M4.75 8.41658V6.83325C4.75 4.21284 5.54167 2.08325 9.5 2.08325C13.4583 2.08325 14.25 4.21284 14.25 6.83325V8.41658"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M13.4583 17.9167H5.54159C2.37492 17.9167 1.58325 17.1251 1.58325 13.9584V12.3751C1.58325 9.20841 2.37492 8.41675 5.54159 8.41675H13.4583C16.6249 8.41675 17.4166 9.20841 17.4166 12.3751V13.9584C17.4166 17.1251 16.6249 17.9167 13.4583 17.9167Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M12.6639 13.1666H12.6711"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M9.49647 13.1666H9.50358"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M6.32899 13.1666H6.3361"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </g>
                                  </svg>
                                  <input
                                    className="input-box"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter your password"
                                    value={userState.password}
                                    onChange={(e) =>
                                      onChangeHandler(
                                        e.target.value,
                                        "password"
                                      )
                                    }
                                  />
                                  <span
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <FaRegEye />
                                    ) : (
                                      <FaRegEyeSlash />
                                    )}
                                  </span>
                                  {/* <svg
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="sc-bdvvtL dTMWEz"
                                  color="#CCD2DD"
                                  fill="none"
                                  height="25"
                                  viewBox="0 0 24 24"
                                  width="25"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    d="M19.25 6.14993C16.94 2.51993 13.56 0.429932 10 0.429932C8.22 0.429932 6.49 0.949932 4.91 1.91993C3.33 2.89993 1.91 4.32993 0.75 6.14993C-0.25 7.71993 -0.25 10.2699 0.75 11.8399C3.06 15.4799 6.44 17.5599 10 17.5599C11.78 17.5599 13.51 17.0399 15.09 16.0699C16.67 15.0899 18.09 13.6599 19.25 11.8399C20.25 10.2799 20.25 7.71993 19.25 6.14993ZM10 13.0399C7.76 13.0399 5.96 11.2299 5.96 8.99993C5.96 6.76993 7.76 4.95993 10 4.95993C12.24 4.95993 14.04 6.76993 14.04 8.99993C14.04 11.2299 12.24 13.0399 10 13.0399Z"
                                    fill="currentColor"
                                    opacity="0.4"
                                  ></path>
                                </svg> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md- col-lg-2 col-12"></div>
                        </div>
                        <div
                          style={{
                            width: "136px",
                            marginTop: "40px",
                            marginBottom: "40px",
                            height: "auto",
                          }}
                        >
                          <button className="signin-btn w-100" onClick={signIn}>
                            Sign in
                          </button>
                        </div>
                        {/* <div style={{ height: "auto" }}>
                        <h6>
                          <span className="confirm-email-txt">
                            <span className="resend-txt">Forgot password?</span>
                          </span>
                        </h6>
                      </div> */}
                        {/* <div style={{ marginBottom: "15px", height: "auto" }}>
                        <h6>
                          <span className="confirm-email-txt">
                            {`Don't have an account?`}
                            <span className="resend-txt">&nbsp;Sign up</span>
                          </span>
                        </h6>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SigninPageWrapper>
  );
};

// eslint-disable-next-line react/prop-types

const SigninPageWrapper = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fc;
  /* background-image: url("https://images.unsplash.com/photo-1460411794035-42aac080490a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
  .app-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    width: 40%;
    align-self: center;
    border: 2px solid #9f9f9f;
    filter: drop-shadow(0 0 0.45rem #2b2b2b);
    background-color: #f5f5f5;

    .title-box {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 24px;
      height: auto;
    }
    .input-box {
      padding: 6px 12px;
      border-radius: 5px;
      font-weight: 400;
      border: 1px solid #9f9f9f;
    }
    .signin-btn {
      padding: 6px 12px;
      border-radius: 5px;
      border: 1px solid #9f9f9f;
      background-color: #4b6bd3;
      color: #f5f5f5;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
`;
