import React, { useState, useEffect } from "react";
import { Input } from "../../../Components/Input";
import { UserInfo } from "../user_info";
import { SideBar } from "../sidebar";

const DEFAULT_STATE = {
  instituteName: "",
  degreeType: "",
  course: "",
  location: "",
  from: "",
  to: "",
};

export const EducationalDetails = ({ getter, setter }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [editState, setEditState] = useState(DEFAULT_STATE);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  useEffect(() => {
    setState(getter?.educational_details);
    setEditState(getter?.educational_details);
  }, []);

  const onEditHandler = (val = "", label = "") => {
    setEditState((prev) => ({ ...prev, [label]: val }));
  };

  const onSaveHandler = () => {
    setState(editState);
    setter(editState, "educational_details");
    setShowSaveModal(false);
    setIsInEditMode(false);
  };

  return (
    <>
      <div className="col-12 sideBar">
        <UserInfo getter={getter?.basic_details} />
        <SideBar />
      </div>
      <div className="row mb-2 profile-view-container">
        <div className="row profile-view-text">
          <div className="profile-heading">
            <h3 className="mx-2">Professional Details</h3>
            <div className="d-flex gap-2">
              <button
                className="edit-btn"
                onClick={() => {
                  isInEditMode
                    ? setShowSaveModal(true)
                    : setIsInEditMode(!isInEditMode);
                }}
              >
                {isInEditMode ? "Save" : "Edit Details"}
              </button>
              {isInEditMode && (
                <button
                  onClick={() => {
                    setIsInEditMode(false);
                    setEditState(state);
                    setShowSaveModal(false);
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Institute Name</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) =>
                    onEditHandler(e.target.value, "instituteName")
                  }
                  value={editState?.instituteName}
                />
              ) : (
                <p className="value">
                  {state?.instituteName ? state?.instituteName : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">degreeType</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "degreeType")}
                  value={editState?.degreeType}
                />
              ) : (
                <p className="value">
                  {state?.degreeType ? state?.degreeType : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">course</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "course")}
                  value={editState?.course}
                />
              ) : (
                <p className="value">{state?.course ? state?.course : "NA"}</p>
              )}
            </div>
          </div>

          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Location</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "location")}
                  value={editState?.location}
                />
              ) : (
                <p className="value">
                  {state?.location ? state?.location : "NA"}
                </p>
              )}
            </div>
          </div>

          <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">from</p>
              {isInEditMode ? (
                <Input
                  type="date"
                  onChange={(e) => onEditHandler(e.target.value, "from")}
                  value={editState?.from}
                />
              ) : (
                <p className="value">{state?.from ? state?.from : "NA"}</p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">to</p>
              {isInEditMode ? (
                <Input
                  type="date"
                  onChange={(e) => onEditHandler(e.target.value, "to")}
                  value={editState?.to}
                />
              ) : (
                <p className="value">{state?.to ? state?.to : "NA"}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <dialog className="modal-popup" open={showSaveModal}>
        <div className="modal-content">
          <p className="modal-text">
            Are you sure you want to keep these edited changes?
          </p>
          <div className="btn-group">
            <button onClick={onSaveHandler} className="modal-btn primary">
              Yes
            </button>
            <button
              onClick={() => setShowSaveModal(false)}
              className="modal-btn secondary"
            >
              No
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};
