import styled from "styled-components";
export const NotFound = () => {
  return (
    <NotFoundContainer>
        <div className="notFound">404</div>
      <div>Sorry, The page you are looking for does not exist !!!</div>
    </NotFoundContainer>
  );
};


export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  div {
    color: #9a9a9a;
    font-size: 18px;
  }
  .notFound {
    font-size: 100px;
  }
`;

