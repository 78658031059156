import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HomeNavbar from '../HomeNavbar/index'
import { HOME_PAGE_DATA } from '../../Constants/Constant';
import HeroBg from '../../assests/images/homepage/hero-bg.png';

export default function HeroContainer(props) {
    const {content} = props;
    const navigate = useNavigate();
  return (
    <HeroContentWrapper>
      <div className="container">
        <div className="row">
          <HomeNavbar options={HOME_PAGE_DATA.navData} />

          <div className="hero-container">
            <div className="main-content">
              <div className="content">
                <div className="content_header">
                  <div className="line" />
                  <div className="sub-content">{content.subHeading}</div>
                  <div className="line" />
                </div>
              </div>
              <div className="content_header">
                <div className="content_header_heading">
                  Create a{' '}
                  <span style={{ color: '#FFFFFF' }}>source of truce</span> for
                  your <span style={{ color: '#FFFFFF' }}>Ideas</span>,{' '}
                  <span style={{ color: '#FFFFFF' }}>Teams</span>, and{' '}
                  <span style={{ color: '#FFFFFF' }}>Projects</span>{' '}
                  <span style={{ color: '#FFFFFF' }}>with us</span>
                </div>
              </div>
              <div
                className="content_button"
                onClick={() => navigate(content.primaryButton.action)}>
                <div className="content_button_title">
                  {content.primaryButton.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroContentWrapper>
  )
}

const HeroContentWrapper = styled.div`
height: calc(100vh - 0px);
  background: url(${HeroBg}),
    linear-gradient(252.69deg, #001542 -0.53%, #070f1f 101.52%);
  background-position: center;
  background-repeat: no-repeat;
  .hero-container {
    height: calc(100vh - 104px);
    display: flex;
    align-items: center;
    justify-content: center;
    .main-content {
      width: 70%;
      text-align: center;
      margin-top: 148px;
      @media (max-width: 991px) {
        width: 100%;
      }
      .content {
        margin: 20px auto;
        .content_header {
          display: flex;
          justify-content: center;
          align-items: center;
          .line {
            height: 2.14px;
            background-color: #ffffff;
            width: 200px;
            @media (max-width: 991px) {
              width: 45px;
            }
          }
          .sub-content {
            font-family: 'Poppins';
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            color: #ffffff;
            margin-left: 10px;
            margin-right: 13px;
            white-space: nowrap;
            @media (max-width: 991px) {
              font-size: 16px;
            }
          }
        }
      }
      .content_header {
        &_heading {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 48px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 20px;
          @media (max-width: 991px) {
            font-size: 30px;
          }
        }
      }
      .content_button {
        width: 30%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #219fff;
        box-shadow: 0px 4px 10px rgba(0, 129, 228, 0.3),
          5px 5px 35px rgba(0, 129, 228, 0.1);
        transform: skew(-25deg);
        margin: 30px auto 0;
        cursor: pointer;
        @media (max-width: 991px) {
          width: 75%;
          padding: 14px;
        }

        &_title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          color: #ffffff;
          padding-right: 20px;
          transform: skew(25deg);
          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
        &_icon {
          border-radius: 50%;
          background-color: #ffffff;
          padding: 3px;
          transform: skew(25deg);
        }
      }
    }
  }
`