

export const UserInfo = ({getter}) => {
    return (
    <div className="sideBar-info">
      <div className="profilePic">
        <p>JD</p>
      </div>
      <div className="side-user-info">
        <h6>
          {getter?.firstName}
          {` `}
          {getter?.lastName}
        </h6>
        <p>{localStorage.getItem("email")}</p>
      </div>
    </div>
  );
};
