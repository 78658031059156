import React from "react";
import styled from "styled-components";

export default function HomeJourney({content}) {
  return (
    <HomeJourneyWrapper id="home-journey" className="home-journey">
      <div className="container">
        <div className="row">
          <div className="home-journey__header">
            {/* <Icon name={content.header_icon} color="#219fff" size={35} /> */}
            <div className="home-journey__header--main">{content.heading}</div>
            <div className="home-journey__header--sub">
              {content.sub_heading}
            </div>
          </div>
          {content.capsules?.map((key, i) => (
            <div
              className="container home-journey__info-section"
              key={key.id + "--" + i}
            >
              <div className="row justify-content-start justify-content-md-end">
                <div className="col-lg-5 d-none d-md-block">
                  <img
                    src={key.imagePath}
                    alt={key.id}
                    className="home-journey__image"
                  />
                </div>
                <div className="col-1 order-first order-md-0 home-journey__middle">
                  <div className="home-journey__middle--wrap" />
                </div>
                <div className="col-11 d-block d-md-none">
                  <div className="row">
                    <img
                      src={key.imagePath}
                      alt={key.id}
                      className="home-journey__image"
                    />
                    <div className="home-journey__info">
                      <div className="home-journey__info--title">
                        {key.title}
                      </div>
                      <div className="home-journey__info--content">
                        {key.content}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-none d-md-block home-journey__info">
                  <div className="home-journey__info--title">{key.title}</div>
                  <div className="home-journey__info--content">
                    {key.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </HomeJourneyWrapper>
  );
}

const HomeJourneyWrapper = styled.div`
margin: 5em 0 0;
.home-journey__ {
  &header {
    text-align: center;
    margin-bottom: 40px;
    &--main {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      color: #7e8f9a;
      margin-top: 10px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
    &--sub {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      color: #219fff;
      margin-top: 10px;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 26px;
      }
    }
  }

  &image {
    width: 80%;

    @media (max-width: 991px) {
      width: 60%;
      margin-bottom: 20px;
    }
  }

  &middle {
    @media (min-width: 991px) {
      padding-left: 0;
    }
    &--wrap {
      background-image: linear-gradient(
        180deg,
        transparent,
        transparent 50%,
        #c4c4c4 50%,
        #c4c4c4 100%
      );
      background-size: 3px 14px;
      background-repeat: repeat-y;
      position: relative;
      width: 3px;
      height: calc(100% + 30px);
      @media (max-width: 991px) {
        height: calc(100% + 50px);
      }

      &:before {
        content: '';
        position: absolute;
        top: -12px;
        left: -6px;
        height: 15px;
        width: 15px;
        background: transparent;
        border-radius: 100px;
        border: 3px solid #c4c4c4;
      }
    }
  }

  &info-section {
    margin-bottom: 40px;
    @media (max-width: 991px) {
      margin-bottom: 70px;
    }
  }

  &info {
    font-family: 'Poppins';
    font-style: normal;

    &--title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #219fff;
    }
    &--content {
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      color: #455a64;
      margin-top: 15px;
    }
  }
}
.top-line {
  position: absolute;
  left: 50%;
  margin-left: -9px;
  margin-top: -65px;
  height: 60px;
  width: 2px;
  display: block;
  background-image: linear-gradient(
    to bottom,
    rgb(73 86 227 / 0),
    rgb(73 86 227 / 1)
  );
}
`;
