import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export const SideBar = () => {
  const location = useParams();
  const [currentMenu, setCurrentMenu] = useState("");

  useEffect(()=>{
    setCurrentMenu(location["*"]);
    return ()=>{
      setCurrentMenu("");
    }
  },[location])

  const navigate = useNavigate();
  const navigateTo = (path) => {
    //   console.log(path);
    navigate(path);
  };

  const setActive = (path)=>{
    return currentMenu === path ? "active" : "";
  }

  return (
    <div className="sideBar-nav">
      <div
        className={`sideBar-items ${setActive('basic_details')}`}
        onClick={()=>navigateTo("/userProfile/basic_details")}
      >
        Basic Details
      </div>
      <div
        className={`sideBar-items ${setActive('educational_details')}`}
        onClick={()=>navigateTo("/userProfile/educational_details")}
      >
        Educational Details
      </div>
      <div
        className={`sideBar-items ${setActive('professional_details')}`}
        onClick={()=>navigateTo("/userProfile/professional_details")}
      >
        Professional Details
      </div>
    </div>
  );
};
