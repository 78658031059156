import React, { useState, useEffect } from "react";
import { Input } from "../../../Components/Input";
import { UserInfo } from "../user_info";
import { SideBar } from "../sidebar";

const DEFAULT_STATE = {
  firstName: "",
  lastName: "",
  country: "",
  state: "",
  city: "",
  email: "",
  contact: "",
  languages: "",
  about: "",
};

export const BasicDetails = ({ getter, setter }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [editState, setEditState] = useState(DEFAULT_STATE);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  useEffect(() => {
    setState(getter?.basic_details);
    setEditState(getter?.basic_details);
  }, []);

  const onEditHandler = (val = "", label = "") => {
    setEditState((prev) => ({ ...prev, [label]: val }));
  };

  const onSaveHandler = () => {
    setState(editState);
    setter(editState, "basic_details");
    setShowSaveModal(false);
    setIsInEditMode(false);
  };

  return (
    <>
      <div className="col-12 sideBar">
        <UserInfo getter={state} />
        <SideBar />
      </div>

      <div className="row mb-2 profile-view-container">
        <div className="row profile-view-text">
          <div className="profile-heading">
            <h3 className="mx-2">Personal Details</h3>
            <div className="d-flex gap-2">
              <button
                className="edit-btn"
                onClick={() => {
                  isInEditMode
                    ? setShowSaveModal(true)
                    : setIsInEditMode(!isInEditMode);
                }}
              >
                {isInEditMode ? "Save" : "Edit Details"}
              </button>
              {isInEditMode && (
                <button
                  onClick={() => {
                    setIsInEditMode(false);
                    setEditState(state);
                    setShowSaveModal(false);
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">First Name</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "firstName")}
                  value={editState?.firstName}
                />
              ) : (
                <p className="value">
                  {state?.firstName ? state?.firstName : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Last Name</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "lastName")}
                  value={editState?.lastName}
                />
              ) : (
                <p className="value">
                  {state?.lastName ? state?.lastName : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Contact Number</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "contact")}
                  value={editState?.contact}
                />
              ) : (
                <p className="value">
                  {state?.contact ? state?.contact : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Email</p>
              <p className="value">{localStorage.getItem("email")}</p>
            </div>
          </div>
          <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Country</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "country")}
                  value={editState?.country}
                />
              ) : (
                <p className="value">
                  {state?.country ? state?.country : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">State</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "state")}
                  value={editState?.state}
                />
              ) : (
                <p className="value">{state?.state ? state?.state : "NA"}</p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">City</p>
              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "city")}
                  value={editState?.city}
                />
              ) : (
                <p className="value">{state?.city ? state?.city : "NA"}</p>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
            <div style={{ marginTop: "10px" }} className="box-container">
              <p className="label">Languages</p>

              {isInEditMode ? (
                <Input
                  type="text"
                  onChange={(e) => onEditHandler(e.target.value, "languages")}
                  value={editState?.languages}
                />
              ) : (
                <p className="value">
                  {state?.languages ? state?.languages : "NA"}
                </p>
              )}
            </div>
          </div>
          <div className="col-12">
            <div style={{ marginTop: "30px" }} className="about-txt">
              <div style={{ marginBottom: "22px" }}>
                <h5 className="label">About me</h5>
                <hr />
              </div>
              {isInEditMode ? (
                <Input
                  type="textarea"
                  onChange={(e) => onEditHandler(e.target.value, "about")}
                  value={editState?.about}
                />
              ) : (
                <p className="value">{state?.about ? state?.about : "NA"}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <dialog className="modal-popup" open={showSaveModal}>
        <div className="modal-content">
          <p className="modal-text">
            Are you sure you want to keep these edited changes?
          </p>
          <div className="btn-group">
            <button onClick={onSaveHandler} className="modal-btn primary">
              Yes
            </button>
            <button
              onClick={() => setShowSaveModal(false)}
              className="modal-btn secondary"
            >
              No
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};
