import { Link, useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import logo from "../../assests/images/mihint.png";
// import hamBurgerBlack from "../../assests/images/hamBurgerBlack.png";
import styled from "styled-components";

export default function HomeNavbar({ options }) {
  const [active, setActive] = useState(options.components[0].id);
  const [openDropdown, setOpenDropdown] = useState(false);
  // const [selectedDropdown, setSelectedDropdown] = useState();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();

  const onNavMenuClick = useCallback(
    (item) => {
      setActive(item.id);
      setOpenDropdown(!openDropdown);
      if (item?.url && !item?.isDropdown) {
        navigate(`${item.url}`, { replace: true });
      }
    },
    [navigate, openDropdown]
  );

  // const onClickDropdownMenu = useCallback(
  //   (item) => {
  //     setSelectedDropdown(item.id);
  //     setOpenDropdown(false);
  //     if (item?.url) {
  //       navigate(`${item.url}`, { replace: true });
  //     }
  //   },
  //   [navigate]
  // );

  // const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <HomeNavWrapper>
      <nav className="navbar navbar-expand-lg homenav">
        <a href={process.env.REACT_APP_BASE_URL} className="navbar-brand">
          <img src={logo} alt="logo" className="homenav_logo" />
        </a>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerHome"
          aria-controls="navbarTogglerHome"
          aria-label="Toggle navigation"
          data-toggle="collapse"
          data-target="#navbarTogglerHome"
          aria-expanded={!isNavCollapsed ? true : false}
          onClick={handleNavCollapse}
        >
          <img src={hamBurgerBlack} className="ham-burger" alt="ham-burger" />
        </button> */}
        <div
          className={isNavCollapsed && "collapse navbar-collapse"}
          id="navbarTogglerHome"
        >
          <div className="homenav-items">
            {options.components.map((item) => {
              // const isDropdownItem = item?.isDropdown;
              return (
                <div key={item.id} className="homenav-items_menu-item">
                  <div
                    className={`
                      homenav-items_menu-item_list
                      ${active === item.id ? "homenav-items_menu_active" : ""}
                    `}
                    onClick={() => onNavMenuClick(item)}
                  >
                    <span
                      className={`
                        homenav-items_menu-item_label
                        ${
                          active === item.id
                            ? "homenav-items_menu-item_list_active"
                            : ""
                        }
                     `}
                    >
                      {item.title}
                    </span>
                    <div></div>
                    {/* {isDropdownItem && (
                      <Icon
                        name={
                          active === item.id && openDropdown
                            ? 'arrow-drop-up'
                            : 'arrow-down'
                        }
                        color="#404F67"
                        className="dropdown-icon"
                        size={active === item.id && openDropdown ? 18 : 22}
                      />
                    )} */}
                  </div>

                  {/* {active === item.id && openDropdown && isDropdownItem && (
                    <OptionWrapper
                      className="option-wrapper"
                      onClick={onClickDropdownMenu}
                      options={item.dropDownItems}
                      selectedOption={selectedDropdown}
                    />
                  )} */}
                </div>
              );
            })}
          </div>
          <div className="left-half">
            {/* <span
              className="invest-link"
              onClick={() => navigate(options.invest.action)}>
              {options.invest.title}
            </span> */}
            <Link className='links' to={`/signin`}>Login</Link>
            {/* <Button
              label={options.primaryButton.title}
              backgroundColor="transparent"
              color="#FFFFFF"
              buttonStyle={{ borderRadius: '25px', padding: '20px' }}
              onClick={() => navigate(options.primaryButton.action)}
            /> */}
          <Link className="links" to={'/signup'}>Sign up</Link>
            {/* <Button
              label={options.secondaryButton.title}
              backgroundColor="#FFFFFF40"
              hoverBackground="#219fff"
              hoverColor="#ffffff"
              color="#FFFFFF"
              buttonStyle={{
                width: '150px',
                borderRadius: '25px',
                padding: '20px',
              }}
              onClick={() => navigate(options.secondaryButton.action)}
            /> */}
          </div>
        </div>
      </nav>
    </HomeNavWrapper>
  );
}

const HomeNavWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  & .homenav {
    position: relative;
    display: flex;
    padding: 10px 5%;
    .collapse {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: 991px) {
      &_logo {
        width: 100px;
      }
      & .navbar-collapse {
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 100;
        background-color: #ffffff;
        width: 100% !important;
        top: 100px;
        left: 0;
        padding-left: 10px;
      }
    }
    @media (min-width: 992px) {
      & .navbar-collapse {
        display: flex !important;
        justify-content: space-between;
        margin: 0 30px;
      }
    }
    & .homenav-items {
      display: flex;
      align-items: center;
      gap: 40px;
      @media (max-width: 1199px) {
        gap: 20px;
      }
      @media (max-width: 992px) {
        display: grid;
        align-items: center;
        gap: 16px;
        margin: 16px;
      }
      &_image-style {
        width: auto;
        height: 45px;
      }
      &_menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & .option-wrapper {
          width: max-content;
        }
        &_list {
          font-size: 18px;
          font-weight: 500;
          color: #404f67;
          cursor: pointer;
          &_active {
            color: #219fff !important;
            &::after {
              content: "";
              position: absolute;
              bottom: -5px;
              left: 30%;
              width: 40%;
              margin: auto;
              border-bottom: 2px solid #219fff;
            }
          }
          & .dropdown-icon {
            position: relative;
            top: 2px;
            left: 6px;
          }

          & .dropdown-icon.rotate-icon-clock {
            color: #219fff !important;
            transform: rotate(180deg);
            transition: 300ms transform;
          }

          &:hover {
            color: #219fff !important;
            & .nmw_menu-item_label {
              border-bottom: 2px solid #219fff;
            }
            & .dropdown-icon {
              color: #219fff !important;
            }
          }
        }
      }
    }
    & .left-half {
      .links {
        text-decoration: none;
        color: white;
      }
      display: flex;
      align-items: center;
      gap: 40px;
      @media (max-width: 1299px) {
        gap: 30px;
      }
      @media (max-width: 992px) {
        display: grid;
        gap: 16px;
        margin: 16px;
      }
      & .invest-link {
        font-weight: 400;
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        color: #219fff;
        cursor: pointer;
      }
    }
  }
`;
