import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LandingPage from '../Components/LandingPage/Index'

export default function Home() {
  return (
    <Routes>
        <Route path='/' element={<LandingPage />} />
    </Routes>
  )
}
